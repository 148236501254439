define("discourse/plugins/discourse-assign/discourse/components/bulk-actions/bulk-assign-user", ["exports", "@glimmer/component", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember-compat/tracked-built-ins", "discourse/plugins/discourse-assign/discourse/components/assign-user-form", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _didInsert, _trackedBuiltIns, _assignUserForm, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _BulkActionsAssignUser;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let BulkActionsAssignUser = _exports.default = (_class = (_BulkActionsAssignUser = class BulkActionsAssignUser extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "model", new _trackedBuiltIns.TrackedObject({}));
      _defineProperty(this, "formApi", {
        submit() {}
      });
    }
    async assign(performAndRefreshCallback1) {
      return performAndRefreshCallback1({
        type: "assign",
        username: this.model.username,
        status: this.model.status,
        note: this.model.note
      });
    }
    performRegistration() {
      this.args.onRegisterAction?.(this.assign.bind(this));
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <span {{didInsert this.performRegistration}}></span>
      <AssignUserForm
        @model={{this.model}}
        @onSubmit={{this.assign}}
        @formApi={{this.formApi}}
      />
    
  */
  {
    "id": "JVQmYH9x",
    "block": "[[[1,\"\\n    \"],[11,1],[4,[32,0],[[30,0,[\"performRegistration\"]]],null],[12],[13],[1,\"\\n    \"],[8,[32,1],null,[[\"@model\",\"@onSubmit\",\"@formApi\"],[[30,0,[\"model\"]],[30,0,[\"assign\"]],[30,0,[\"formApi\"]]]],null],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-assign/discourse/components/bulk-actions/bulk-assign-user.js",
    "scope": () => [_didInsert.default, _assignUserForm.default],
    "isStrictMode": true
  }), _BulkActionsAssignUser), _BulkActionsAssignUser), (_applyDecoratedDescriptor(_class.prototype, "assign", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "assign"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "performRegistration", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "performRegistration"), _class.prototype)), _class);
});