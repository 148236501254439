define("discourse/plugins/discourse-assign/discourse/connectors/groups-interaction-custom-options/assignable-interaction-fields", ["exports", "@glimmer/component", "@ember/object", "I18n"], function (_exports, _component, _object, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let AssignableInteractionFields = _exports.default = (_class = class AssignableInteractionFields extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "assignableLevelOptions", [{
        name: _I18n.default.t("groups.alias_levels.nobody"),
        value: 0
      }, {
        name: _I18n.default.t("groups.alias_levels.only_admins"),
        value: 1
      }, {
        name: _I18n.default.t("groups.alias_levels.mods_and_admins"),
        value: 2
      }, {
        name: _I18n.default.t("groups.alias_levels.members_mods_and_admins"),
        value: 3
      }, {
        name: _I18n.default.t("groups.alias_levels.owners_mods_and_admins"),
        value: 4
      }, {
        name: _I18n.default.t("groups.alias_levels.everyone"),
        value: 99
      }]);
    }
    get assignableLevel() {
      return this.args.outletArgs.model.get("assignable_level") || 0;
    }
    onChangeAssignableLevel(level) {
      this.args.outletArgs.model.set("assignable_level", level);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onChangeAssignableLevel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeAssignableLevel"), _class.prototype)), _class);
});